import {
  BxlFacebook,
  BxlInstagram,
  BxlLinkedin,
} from '@opengovsg/design-system-react'

import { ReactComponent as DisLogo } from '~assets/dis-logo.svg'
import { ReactComponent as DotcLogo } from '~assets/dotc-logo.svg'
import {
  CONTACT_PINPOINT_URL,
  DIS_FACEBOOK,
  DIS_INSTAGRAM,
  DIS_LINKEDIN,
  DIS_WEBSITE_URL,
  DOTC_WEBSITE_URL,
  GOVTECH_REPORT_VULNERABILITY_URL,
  PINPOINT_FAQ_URL,
  PINPOINT_GUIDE_URL,
  PINPOINT_PRIVACY_URL,
  PINPOINT_TOU_URL,
} from '~shared/constants/links'

import {
  FooterLink,
  FooterLinkWithIcon,
  FooterLinkWithIconAndHeader,
} from './types'

export const DEFAULT_FOOTER_LINKS: FooterLink[] = [
  {
    label: 'Contact',
    href: CONTACT_PINPOINT_URL,
  },
  {
    label: 'FAQ',
    href: PINPOINT_FAQ_URL,
  },
  {
    label: 'Guide',
    href: PINPOINT_GUIDE_URL,
  },
  {
    label: 'Privacy',
    href: PINPOINT_PRIVACY_URL,
  },
  {
    label: 'Terms of use',
    href: PINPOINT_TOU_URL,
  },
  {
    label: 'Report vulnerability',
    href: GOVTECH_REPORT_VULNERABILITY_URL,
  },
]

export const DEFAULT_FOOTER_ICON_LINKS: FooterLinkWithIconAndHeader[] = [
  {
    href: DOTC_WEBSITE_URL,
    label: 'Digital Ops Tech Centre',
    header: 'Built by',
    Icon: DotcLogo,
  },
  {
    href: DIS_WEBSITE_URL,
    label: 'Digital and Intelligence Service',
    header: 'A part of',
    Icon: DisLogo,
  },
]

export const DEFAULT_SOCIAL_MEDIA_LINKS: FooterLinkWithIcon[] = [
  {
    href: DIS_INSTAGRAM,
    Icon: BxlInstagram,
    label: 'Go to our Instagram page',
  },
  {
    href: DIS_LINKEDIN,
    Icon: BxlLinkedin,
    label: 'Go to our LinkedIn page',
  },
  {
    href: DIS_FACEBOOK,
    Icon: BxlFacebook,
    label: 'Go to our Facebook page',
  },
]
