export const GALLERY_MENU_TAGS = 'getTagsToDisplay'

export const SELECTED_IMAGE_METADATA = 'getSelectedImageMetadata'

export const APPEND_TAGS = 'appendTags'

export const DELETE_TAGS = 'deleteTags'

export const REPLACE_TAGS = 'replaceTags'

export const ALL_WORKSPACES = 'getAllWorkspaces'

export const ALL_WORKSPACES_ROLE_PERMISSIONS = 'getAllWorkspacesRolePermissions'

export const ADD_WORKSPACE = 'addWorkspace'

export const DELETE_WORKSPACE = 'deleteWorkspace'

export const RENAME_WORKSPACE = 'renameWorkspace'

export const WORKSPACE_PERMISSIONS = 'getWorkspacePermissions'

export const WORKSPACE_ALBUMS = 'getWorkspaceAlbums'

export const IMAGES = 'images'

export const UPDATE_IMAGE_METADATA = 'updateImageMetadata'

export const WORKSPACE_USERS = 'getWorkspaceUsers'

export const WORKSPACE_KEY = 'workspace'

export const GET_BANNER = 'getBanner'

export const SHARE_LINK_KEY = 'shareLink'

export const SHARE_LINK_MEDIA = 'shareLinkMedia'

export const THUMBNAIL = 'thumbnail'
